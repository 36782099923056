import {
  InvestorGoalInvestmentProductFundTypeEntity,
} from '@/modules/flagship/investor-goal/investment_product_fund_types/domain/entities/investor_goal_investment_product_fund_type_entity';

export function verifyIfInvestmentProductFundTypeIsLinked(
  linked_investment_product_funds: Array<InvestorGoalInvestmentProductFundTypeEntity>,
): boolean {
  const linked = linked_investment_product_funds.find(
    (item) => item.name === 'SWS-MODERADO',
  );
  return !!linked;
}
